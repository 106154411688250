<template>
  <div class="container">
    <el-form ref="form" :model="form">
      <el-form-item label="城市">
        <el-radio v-model="city" label="杭州市">杭州市</el-radio>
        <el-radio v-model="city" label="无锡市">无锡市</el-radio>
        <el-input v-model="city" placeholder="其他城市"></el-input>
      </el-form-item>

      <el-form-item label="日期">
        <el-date-picker
          v-model="time"
          value-format="yyyy-MM-dd"
          type="date"
          placeholder="选择日期"
        >
        </el-date-picker>
      </el-form-item>
      <div class="part">用户数据</div>
      <div class="flex">
        <el-form-item label="当前城市用户总数">
          <el-input v-model.number="form.user.total"></el-input>
        </el-form-item>
        <el-form-item label="用户新增">
          <el-input v-model.number="form.user.newCount"></el-input>
        </el-form-item>
        <el-form-item label="用户在线">
          <el-input v-model.number="form.user.onlineCount"></el-input>
        </el-form-item>
        <el-form-item label="新增会员">
          <el-input v-model.number="form.user.memberCount"></el-input>
        </el-form-item>
      </div>
      <div class="flex">
        <el-form-item label="用户来源">
          <el-input
            v-for="(n, i) in form.user.typeName"
            :key="i"
            placeholder="请输入内容"
            v-model.number="form.user.typeList[i]"
          >
            <template slot="prepend">{{ n }}</template>
          </el-input>
        </el-form-item>
      </div>
      <div>
        <div class="flex">
          <el-form-item label="新增用户各时间段数据">
            <div class="flex">
              <el-tag
                v-for="(tag, i) in form.user.today"
                @close="closeTag('user', 'today', i)"
                :key="i"
                closable
              >
                {{ tag.hour }}，{{ tag.count }}
              </el-tag>
            </div>
          </el-form-item>
        </div>
        <el-popover placement="right" width="400" trigger="click">
          <el-form>
            <el-form-item label="时间">
              <el-time-select
                v-model="hour"
                :picker-options="{
                  start: '06:00',
                  step: '01:00',
                  end: '23:00',
                }"
                placeholder="选择时间"
              >
              </el-time-select>
            </el-form-item>
            <el-form-item label="数量">
              <el-input v-model.number="count" placeholder="数量"></el-input>
            </el-form-item>
            <el-button @click="addToday('user', 'today')">确定</el-button>
          </el-form>
          <el-button type="primary" slot="reference">添加用户数据</el-button>
        </el-popover>
      </div>
      <div class="part">订单数据</div>
      <div class="flex">
        <el-form-item label="订单收入">
          <el-input v-model.number="form.income.income"></el-input>
        </el-form-item>
        <el-form-item label="分佣金额">
          <el-input v-model.number="form.income.cash"></el-input>
        </el-form-item>
      </div>
      <div class="flex">
        <el-form-item label="分佣明细">
          <el-input
            v-for="(n, i) in form.income.typeName"
            :key="i"
            placeholder="请输入内容"
            v-model.number="form.income.typeList[i]"
          >
            <template slot="prepend">{{ n }}</template>
          </el-input>
        </el-form-item>
      </div>
      <div>
        <div class="flex">
          <el-form-item label="订单收益时间段数据">
            <div class="flex">
              <el-tag
                v-for="(tag, i) in form.income.today"
                @close="closeTag('income', 'today', i)"
                :key="i"
                closable
              >
                {{ tag.hour }}，{{ tag.count }}
              </el-tag>
            </div>
          </el-form-item>
        </div>
        <el-popover placement="right" width="400" trigger="click">
          <el-form>
            <el-form-item label="时间">
              <el-time-select
                v-model="hour"
                :picker-options="{
                  start: '06:00',
                  step: '01:00',
                  end: '23:00',
                }"
                placeholder="选择时间"
              >
              </el-time-select>
            </el-form-item>
            <el-form-item label="金额">
              <el-input v-model.number="count" placeholder="金额"></el-input>
            </el-form-item>
            <el-button @click="addToday('income', 'today')">确定</el-button>
          </el-form>
          <el-button type="primary" slot="reference"
            >增加订单收益数据</el-button
          >
        </el-popover>
      </div>

      <div>
        <div class="flex">
          <el-form-item label="分佣时间段数据">
            <div class="flex">
              <el-tag
                v-for="(tag, i) in form.income.cashToday"
                @close="closeTag('income', 'cashToday', i)"
                :key="i"
                closable
              >
                {{ tag.hour }}，{{ tag.count }}
              </el-tag>
            </div>
          </el-form-item>
        </div>
        <el-popover placement="right" width="400" trigger="click">
          <el-form>
            <el-form-item label="时间">
              <el-time-select
                v-model="hour"
                :picker-options="{
                  start: '06:00',
                  step: '01:00',
                  end: '23:00',
                }"
                placeholder="选择时间"
              >
              </el-time-select>
            </el-form-item>
            <el-form-item label="金额">
              <el-input v-model="count" placeholder="金额"></el-input>
            </el-form-item>
            <el-button @click="addToday('income', 'cashToday')">确定</el-button>
          </el-form>
          <el-button type="primary" slot="reference">分佣时间段数据</el-button>
        </el-popover>
      </div>
      <div class="part">教练数据</div>
      <div class="flex">
        <el-form-item label="当前城市教练总数">
          <el-input v-model.number="form.coach.total"></el-input>
        </el-form-item>
        <el-form-item label="教练新增">
          <el-input v-model.number="form.coach.newCount"></el-input>
        </el-form-item>
        <el-form-item label="教练在线">
          <el-input v-model.number="form.coach.onlineCount"></el-input>
        </el-form-item>
        <el-form-item label="教练会员">
          <el-input v-model.number="form.coach.memberCount"></el-input>
        </el-form-item>
      </div>
      <div class="flex">
        <el-form-item label="新增类型">
          <el-input
            v-for="(n, i) in form.coach.typeName"
            :key="i"
            placeholder="请输入内容"
            v-model.number="form.coach.typeList[i]"
          >
            <template slot="prepend">{{ n }}</template>
          </el-input>
        </el-form-item>
      </div>
      <div>
        <div class="flex">
          <el-form-item label="新增数量时间段数据">
            <div class="flex">
              <el-tag
                v-for="(tag, i) in form.coach.today"
                @close="closeTag('coach', 'today', i)"
                :key="i"
                closable
              >
                {{ tag.hour }}，{{ tag.count }}
              </el-tag>
            </div>
          </el-form-item>
        </div>
        <el-popover placement="right" width="400" trigger="click">
          <el-form>
            <el-form-item label="时间">
              <el-time-select
                v-model="hour"
                :picker-options="{
                  start: '06:00',
                  step: '01:00',
                  end: '23:00',
                }"
                placeholder="选择时间"
              >
              </el-time-select>
            </el-form-item>
            <el-form-item label="数量">
              <el-input v-model="count" placeholder="数量"></el-input>
            </el-form-item>
            <el-button @click="addToday('coach', 'today')">确定</el-button>
          </el-form>
          <el-button type="primary" slot="reference">增加新增数据</el-button>
        </el-popover>
      </div>
      <div class="part">课程数据</div>
      <div class="flex">
        <el-form-item label="上课数量">
          <el-input v-model.number="form.course.total"></el-input>
        </el-form-item>
        <el-form-item label="未开始">
          <el-input v-model.number="form.course.start"></el-input>
        </el-form-item>
        <el-form-item label="已结束">
          <el-input v-model.number="form.course.end"></el-input>
        </el-form-item>
        <el-form-item label="已核销">
          <el-input v-model.number="form.course.settleCount"></el-input>
        </el-form-item>
      </div>
       <el-form-item label="今日新增机构数">
          <el-input v-model.number="form.course.mecCount"></el-input>
        </el-form-item>
    </el-form>
    <el-button v-if="id" type="success" @click="update">修改数据</el-button>
    <el-button v-else type="warning" @click="add">新增数据</el-button>
  </div>
</template>

<script>
export default {
  data() {
    return {
      id: "",
      time: "",
      city: "杭州市",
      count: 0,
      hour: "",
      form: {
        user: {
          total: 16569,
          newCount: 10,
          onlineCount: 94,
          memberCount: 6,
          typeName: ["微信小程序", "ios", "安卓", "抖音小程序", "其他"],
          typeList: [5, 2, 2, 1, 0],
          today: [{ hour: "12:00", count: 10 }],
        },
        income: {
          income: 2000,
          cash: 300,
          today: [{ hour: "12:00", count: 100 }],
          cashToday: [{ hour: "12:00", count: 100 }],
          typeName: ["课程佣金", "会员佣金", "其他佣金"],
          typeList: [200, 100, 0],
        },
        coach: {
          memberCount: 2,
          newCount: 10,
          onlineCount: 86,
          total: 2956,
          typeName: ["普通教练", "会员教练", "特约教练"],
          typeList: [8, 2, 0],
          today: [{ hour: "12:00", count: 10 }],
        },
        course: {
          end: 6,
          settleCount: 6,
          start: 0,
          total: 6,
          mecCount:0,
        },
      },
    };
  },

  mounted() {
    if (this.$route.query.id) {
      this.id = this.$route.query.id;
      this.getInfo();
    }
  },

  methods: {
    getInfo() {
      this.$axios({
        url: "/user/sysParam/findById",
        params: {
          id: this.$route.query.id,
        },
      }).then((res) => {
        let v = res.data.data;
        this.form = JSON.parse(v.param_value);
        this.city = v.remark;
        this.time = v.update_time.split(" ")[0];
      });
    },
    closeTag(v, v2, i) {
      this.form[v][v2].splice(i, 1);
    },
    addToday(v, v2) {
      this.form[v][v2].push({
        hour: this.hour,
        count: Number(this.count),
      });
    },
    update() {
      this.$axios({
        url: "/user/sysParam/update",
        method: "post",
        data: {
          id: this.id,
          remark: this.city,
          param_key: this.city + "-" + this.time,
          param_value: JSON.stringify(this.form),
          update_time: this.time + " 00:00:00",
          type: "data",
        },
      }).then((res) => {
        this.$router.back();
      });
    },
    add() {
      if (!this.time) {
        this.$message("请选择时间");
        return;
      }
      this.$axios({
        url: "/user/sysParam/insert",
        method: "post",
        data: {
          remark: this.city,
          param_key: this.city + "-" + this.time,
          param_value: JSON.stringify(this.form),
          update_time: this.time + " 00:00:00",
          type: "data",
        },
      }).then((res) => {
        this.$router.back();
      });
    },
    //分成记录
    getList(v) {
      let page = v || 1;
      this.$axios({
        url: "/user/adminBonusLog/queryManagerListPage",
        params: {
          currentPage: page,
          pageSize: 10,
          cooperator_id: this.cooperator_id,
          type: this.type || null,
        },
      }).then((res) => {
        this.DataList = res.data.data.rows;
        this.Total = res.data.data.total;
      });
    },
  },
};
</script>

<style lang="less" scoped>
.flex {
  display: flex;
}
.part {
  font-size: 16px;
  height: 30px;
  font-weight: 600;
}
</style>